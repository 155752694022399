export const brand = "Todd Brannon"
export const Copyright = new Date().getFullYear() + " "

export const MarketPlaceAppLink = "https://rc-marketplace-app-alt-1.vercel.app/"
export const PeakFlowRateCalcLink = "https://peak-flow-rate-calculator.onrender.com/"
export const ValinorLink = "https://sb1383-calculator.onrender.com"
export const HealthFlowLink = "https://healthflow-wy35.onrender.com/"
export const DividendReinvestmentLink = "https://dividend-reinvestment.onrender.com/"
export const TruSponseSolutionsLink = "https://www.trusponse.com"
export const LCR_ProjectLink = "https://www.youtube.com/watch?v=F3YP_dx8JdM"
export const NutritionFormLink = "https://youtu.be/JKkRVDthL0M"
export const CARAppLink = "https://youtu.be/Y4mpLW1uyYM"
export const JonBeerToolLink = "https://youtu.be/5s6QvRvkclw"
export const LCRAppLink = "https://youtu.be/aEeMq65BWN8"
export const LCRExcelLink = "https://youtu.be/DNzzzj806KM"

